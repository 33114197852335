import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { useWindowSize } from '../../utils';
import MenuIcon from '../../icons/menuIcon';
import { isSignedIn } from '../LoginModal';
import Modal from '../Modal/Modal';
import GaEvents from '../../lib/googleAnalytics';
import DemoPageForm from '../DemoPageForm';
import { DASHBOARD } from '../../constants';

const LogoLandingPage = lazy(() => import('../../icons/logoLandingPage'));
const LoginModal = lazy(() => import('../LoginModal'));

export default function LandingPageHeader() {
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [demoModal, setDemoModal] = useState(false);
  const windowSize = useWindowSize();
  const [isScrolled, setIsScrolled] = useState(false);
  const isLaptop = windowSize.width > 960;

  const handleFeaturesClick = () => {
    const featuresPageElement = document.getElementById('features');
    if (featuresPageElement) {
      featuresPageElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // const handlePricingClick = () => {
  //   const pricingPageElement = document.getElementById('pricing');
  //   if (pricingPageElement) {
  //     pricingPageElement.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 90;
      if (scrollY > threshold && !isScrolled) {
        setIsScrolled(true);
      } else if (scrollY <= threshold && isScrolled) {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  return (
    <>
      {isLaptop === true ? (
        <div className="landing-page-headingv1">
          <div className="landing-page-heading-wrapper">
            <Link to="/">
              <div
                data-testid="logo-icon"
                className="logo-icon"
                onClick={() => {
                  GaEvents({
                    category: 'Landing Page',
                    action: `Click on EU logo in landing page`,
                    label: 'navigate to top of page',
                  });
                  handleLogoClick();
                }}
                id=""
              >
                <LogoLandingPage />
              </div>
            </Link>
            <div className="landing-page-heading-text-align">
              <Link to="/#features" data-testid="landing-page-features-link">
                <h3
                  className="landing-page-heading-text"
                  onClick={() => {
                    GaEvents({
                      category: 'Landing Page',
                      action: `Click on Features in landing page`,
                      label: 'navigate to features',
                    });
                    handleFeaturesClick();
                  }}
                >
                  Features
                </h3>
              </Link>
              {/* <Link to="/#pricing" data-testid="landing-page-Pricing-link">
                <h3
                  className="landing-page-heading-text"
                  onClick={() => {
                    GaEvents({
                      category: 'Landing Page',
                      action: `Click on Pricing in landing page`,
                      label: 'navigate to pricing',
                    });
                    handlePricingClick();
                  }}
                >
                  Pricing
                </h3>
              </Link> */}
              <Link to="/blog" data-testid="blog-listing-page-link">
                <h3
                  className="landing-page-heading-text"
                  onClick={() => {
                    GaEvents({
                      category: 'Landing Page',
                      action: `Click on Blog in landing page`,
                      label: 'navigate to blog listing page',
                    });
                  }}
                >
                  Blog
                </h3>
              </Link>
            </div>
            <div className="buttons">
              <button
                className="landing-page-getstarted-up-buttonv1"
                onClick={() => {
                  GaEvents({
                    category: 'Landing Page',
                    action: `Click on Request For Demo button in landing page`,
                    label: 'navigate to login page',
                  });
                  setDemoModal(true);
                }}
              >
                <p className="landing-page-sign-up-text">Request For Demo</p>
              </button>
              {isSignedIn() === true ? (
                <a
                  className="landing-page-sign-up-buttonv1"
                  href={`${window.location.origin}${DASHBOARD}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="landing-page-sign-up-text">My Dashboard</p>
                </a>
              ) : (
                <button
                  className="landing-page-sign-up-buttonv1"
                  onClick={() => {
                    GaEvents({
                      category: 'Landing Page',
                      action: `Click on Sign In button in landing page`,
                      label: 'navigate to login page',
                    });
                    setIsLoginModal(true);
                  }}
                >
                  <p className="landing-page-sign-up-text">Sign In</p>
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="landing-page-heading-mobile">
          <Link to="/">
            <div
              className="logo-icon"
              onClick={() => {
                GaEvents({
                  category: 'Landing Page',
                  action: `Click on EU logo in landing page`,
                  label: 'navigate to top of page',
                });
                handleLogoClick();
              }}
              id=""
            >
              <LogoLandingPage />
            </div>
          </Link>

          <label className="menu-icon" htmlFor="nav-toggle">
            {MenuIcon()}
          </label>
        </div>
      )}
      {demoModal && (
        <Modal>
          <DemoPageForm setOpenPopup={setDemoModal} />
        </Modal>
      )}
      {isLoginModal && (
        <Suspense fallback={<></>}>
          <LoginModal setIsLoginModal={setIsLoginModal} />
        </Suspense>
      )}
    </>
  );
}
