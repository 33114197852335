import { MICROSOFT_LOGIN_CLIENT_ID } from './constants';

export const msalConfig = {
  auth: {
    clientId: MICROSOFT_LOGIN_CLIENT_ID as string,
    redirectUri: `${window.origin}/user/login`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};
export const loginRequest = {
  scopes: ['user.read', 'openid', 'profile'],
};
