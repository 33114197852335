import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './style.scss';
import eazyUpdates from '../../icons/workSpaceLogo.jsx';
import { toast } from 'react-toastify';
import { SECRET_KEY } from '../../constants.js';
import { useWindowSize } from '../../utils';
import DemoSuccessImage from '../../icons/demoSuccessImage.jsx';
import LoginModalCancel from '../../icons/loginModalCancel.jsx';
import DemoFormSvgMobile from '../../icons/demoFormSvgMobile.jsx';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { postDemoRequest } from '../../lib/ModalAuth/index.js';
import Phone from '../Phone/index.js';

const DemoPageForm = ({ setOpenPopup }) => {
  const [demoFormData, setDemoFormData] = useState({
    name: '',
    email: '',
    phone: '',
    companyWebsite: '',
    description: '',
    flag: true,
  });
  const [captcha, setCaptcha] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const reCaptchaRef = useRef(null);
  const [validationErrors, setValidationErrors] = useState({
    name: '',
    email: '',
    phone: '',
    companyWebsite: '',
    description: '',
  });
  const windowSize = useWindowSize();
  const isLaptop = windowSize.width > 960;
  const getInTouchForm = async () => {
    if (
      captcha !== '' &&
      (demoFormData.email !== '' || demoFormData.phone !== '' || demoFormData.phone === undefined)
    ) {
      try {
        const res = await postDemoRequest({
          name: demoFormData.name,
          email: demoFormData.email,
          phoneNumber: demoFormData.phone,
          message: demoFormData.description,
          website: demoFormData.companyWebsite,
          flag: demoFormData.flag,
        });
        if (res?.data !== undefined) {
          setDemoFormData(res.data);
          setLoading(false);
          setSuccess(true);
        } else {
          throw new Error(res);
        }
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    }
  };
  const handleCaptcha = (value) => {
    setCaptcha(value);
  };
  const getValidationMessage = (fieldName, values) => {
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    const domainRegex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.+~#?&//=]*)/g;
    switch (fieldName) {
      case 'email':
        return values.length > 0 && !emailRegex.test(values) ? 'enter valid email' : '';
      case 'name':
        return values.length === 0 ? 'name cannot be empty' : '';
      case 'phone':
        return values !== undefined && !isValidPhoneNumber(values)
          ? 'enter valid phone number'
          : '';
      case 'companyWebsite':
        return values.length > 0 && !domainRegex.test(values) ? 'enter valid url' : '';
      default:
        return;
    }
  };
  const handleInputChange = (fieldName, value) => {
    setDemoFormData({ ...demoFormData, [fieldName]: value });
    setValidationErrors({
      ...validationErrors,
      [fieldName]: getValidationMessage(fieldName, value),
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const hasErrors = Object.values(validationErrors).some((error) => error !== '');
    setLoading(true);
    if (!hasErrors) {
      getInTouchForm();
    }
  };

  return (
    <div className={isLaptop ? 'demo-page-form' : 'demo-page-form-mobile'}>
      {isLaptop ? (
        <>
          <div>
            <div className="demo-header">
              {eazyUpdates({
                color: '#5C8AFF',
                height: '40px',
              })}
              <div
                className="cancel-icon"
                onClick={() => {
                  setOpenPopup !== undefined && setOpenPopup(false);
                }}
              >
                {LoginModalCancel()}
              </div>
            </div>
          </div>
          {!success ? (
            <div className="demopage-wrapper">
              <div className="right-container">
                <img
                  src="https://storage.googleapis.com/zopping-staging-uploads/2497/images/originals/20240530/815b4389-f42c-460a-9d02-288d029faabf-imagefile.webp"
                  alt="demoformSvg"
                  style={{ width: '432px', height: '316px' }}
                ></img>
                <div className="text1">Book a free demo at a time that suits you.</div>
                <div className="text2">
                  Learn about all the functionalities in EazyUpdates that can improve your
                  team&apos;s productivity.
                </div>
              </div>
              <div className="form-wrapper">
                <div className="demo-page-form-text">
                  Fill out the form and we&apos;ll be in touch as soon as possible.
                </div>
                <form className="demo-page-form-fields" onSubmit={handleSubmit}>
                  <div className="demo-page-form-label-fields">
                    <div className="field-wrapper">
                      <label className="demo-page-form-field-name">Name *</label>
                      <div className="name-input-wrapper">
                        <input
                          type="text"
                          className="demo-page-form-inputs"
                          placeholder="Name"
                          value={demoFormData.name}
                          onChange={(e) => handleInputChange('name', e.target.value)}
                          required
                        ></input>
                        {validationErrors.name.length !== 0 && (
                          <div className="error-message">{validationErrors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="field-wrapper">
                      <label className="demo-page-form-field-name">Email *</label>
                      <div className="email-input-wrapper">
                        <input
                          type="email"
                          className="demo-page-form-inputs"
                          placeholder="Email"
                          value={demoFormData.email}
                          onChange={(e) => handleInputChange('email', e.target.value)}
                        ></input>
                        {validationErrors.email.length !== 0 &&
                          validationErrors.email.trim() !== '' && (
                            <div className="error-message">{validationErrors.email}</div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="demo-page-form-label-fields">
                    <div className="field-wrapper">
                      <label className="demo-page-form-field-name">Phone *</label>
                      <div className="phonenumber-input-wrapper">
                        <Phone
                          placeholder="Phone"
                          className="demo-page-form-inputs"
                          value={demoFormData.phone}
                          onChange={(value) => handleInputChange('phone', value)}
                        />
                        {validationErrors.phone.length !== 0 && (
                          <div className="error-message">{validationErrors.phone}</div>
                        )}
                      </div>
                    </div>
                    <div className="field-wrapper">
                      <label className="demo-page-form-field-name">Company Website</label>
                      <div className="domain-input-wrapper">
                        <input
                          type="text"
                          className="demo-page-form-inputs"
                          placeholder="Company Website"
                          value={demoFormData.companyWebsite}
                          onChange={(e) => handleInputChange('companyWebsite', e.target.value)}
                        ></input>
                        {validationErrors.companyWebsite.length !== 0 && (
                          <div className="error-message">{validationErrors.companyWebsite}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <span className="demo-page-form-field-name">Description</span>
                  <textarea
                    className="demo-page-form-inputs-description"
                    placeholder="Type here"
                    rows={4}
                    value={demoFormData.description}
                    onChange={(e) => {
                      setDemoFormData({
                        ...demoFormData,
                        description: e.target.value,
                      });
                    }}
                  ></textarea>
                  <div className="demo-page-input-checkbox-text">
                    <input
                      type="checkbox"
                      className="admin-checkbox-input"
                      checked={demoFormData.flag === true}
                      onChange={(e) => {
                        setDemoFormData({
                          ...demoFormData,
                          flag: !demoFormData.flag,
                        });
                      }}
                    />

                    <span className="demo-page-checkbox">
                      Yes, I&apos;d like to receive occasional marketing emails from us. I have the
                      right to opt out at any time.
                    </span>
                  </div>
                  <div className="captcha-button">
                    <ReCAPTCHA
                      sitekey={SECRET_KEY != null ? SECRET_KEY : ''}
                      theme="light"
                      onChange={(token) => {
                        if (token !== null) {
                          handleCaptcha(token);
                        }
                      }}
                      ref={reCaptchaRef}
                      onExpired={() => setCaptcha('')}
                    />
                  </div>
                  <button
                    className={
                      captcha !== '' &&
                      (demoFormData.email !== '' ||
                        (demoFormData.phone !== undefined && demoFormData.phone !== ''))
                        ? 'demo-page-submit-button'
                        : 'demo-page-submit-button-disabled'
                    }
                    type="submit"
                    disabled={
                      captcha === '' ||
                      (demoFormData.email === '' &&
                        (demoFormData.phone === '' || demoFormData.phone === undefined))
                    }
                  >
                    {loading ? 'Loading...' : 'Submit'}
                  </button>
                </form>
              </div>
            </div>
          ) : (
            <div className="successpage-wrapper">
              <div className="success-image">
                <DemoSuccessImage width="431.68px" height="316px" />
              </div>
              <div className="success-msg">
                Thanks We've received your request and will be in touch soon.
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="demo-header">
            {eazyUpdates({
              color: '#5C8AFF',
              height: '40px',
            })}
            <div
              className="cancel-icon"
              onClick={() => {
                setOpenPopup !== undefined && setOpenPopup(false);
              }}
            >
              {LoginModalCancel()}
            </div>
          </div>
          {!success ? (
            <div className="demopage-wrapper-mobile">
              <div className="right-container">
                <div className="PopupImage">{DemoFormSvgMobile()}</div>
                <div className="text1">Book a free demo at a time that suits you.</div>
                <div className="text2">
                  Learn about all the functionalities in EazyUpdates that can improve your
                  team&apos;s productivity.
                </div>
              </div>
              <div className="form-wrapper-mobile">
                <form className="demo-page-form-fields" onSubmit={handleSubmit}>
                  <div className="demo-page-form-label-fields-mobile">
                    <span className="demo-page-form-field-name">
                      Name *
                      <input
                        type="text"
                        className="demo-page-form-inputs"
                        placeholder="Name"
                        value={demoFormData.name}
                        onChange={(e) => handleInputChange('name', e.target.value)}
                        required
                      ></input>
                      {validationErrors.name.length !== 0 && (
                        <div className="error-message">{validationErrors.name}</div>
                      )}
                    </span>
                    <span className="demo-page-form-field-name">
                      Email *
                      <input
                        type="email"
                        className="demo-page-form-inputs"
                        placeholder="Email"
                        value={demoFormData.email}
                        onChange={(e) => {
                          handleInputChange('email', e.target.value);
                        }}
                      ></input>
                      {validationErrors.email.length !== 0 && (
                        <div className="error-message">{validationErrors.email}</div>
                      )}
                    </span>
                    <span className="demo-page-form-field-name">
                      Phone *
                      <Phone
                        placeholder="Phone"
                        className="demo-page-form-inputs"
                        value={demoFormData.phone}
                        onChange={(value) => handleInputChange('phone', value)}
                      />
                      {validationErrors.phone.length !== 0 && (
                        <div className="error-message">{validationErrors.phone}</div>
                      )}
                    </span>
                    <span className="demo-page-form-field-name">
                      Company Website
                      <input
                        type="text"
                        className="demo-page-form-inputs"
                        placeholder="Company Website"
                        value={demoFormData.companyWebsite}
                        onChange={(e) => {
                          handleInputChange('companyWebsite', e.target.value);
                        }}
                      ></input>
                      {validationErrors.companyWebsite.length !== 0 && (
                        <div className="error-message">{validationErrors.companyWebsite}</div>
                      )}
                    </span>
                    <span className="demo-page-form-field-name">
                      Description
                      <textarea
                        className="demo-page-form-inputs-description"
                        placeholder="Type here"
                        rows={4}
                        value={demoFormData.description}
                        onChange={(e) => {
                          setDemoFormData({
                            ...demoFormData,
                            description: e.target.value,
                          });
                        }}
                      ></textarea>
                    </span>
                  </div>
                  <div className="demo-page-input-checkbox-text">
                    <input
                      type="checkbox"
                      className="admin-checkbox-input"
                      checked={demoFormData.flag === true}
                      onChange={(e) => {
                        setDemoFormData({
                          ...demoFormData,
                          flag: !demoFormData.flag,
                        });
                      }}
                    />

                    <span className="demo-page-checkbox">
                      Yes, I&apos;d like to receive occasional marketing emails from us. I have the
                      right to opt out at any time.
                    </span>
                  </div>
                  <div className="captcha-button">
                    <ReCAPTCHA
                      sitekey={SECRET_KEY != null ? SECRET_KEY : ''}
                      theme="light"
                      onChange={(token) => {
                        if (token !== null) {
                          handleCaptcha(token);
                        }
                      }}
                      ref={reCaptchaRef}
                      onExpired={() => setCaptcha('')}
                    />
                  </div>
                  <button
                    className={
                      captcha !== '' &&
                      (demoFormData.email !== '' ||
                        (demoFormData.phone !== undefined && demoFormData.phone !== ''))
                        ? 'demo-page-submit-button-mobile'
                        : 'demo-page-submit-button-disabled'
                    }
                    type="submit"
                    disabled={
                      captcha === '' ||
                      (demoFormData.email === '' &&
                        (demoFormData.phone === '' || demoFormData.phone === undefined))
                    }
                  >
                    {loading ? 'Loading...' : 'Submit'}
                  </button>
                </form>
              </div>
            </div>
          ) : (
            <div className="successpage-wrapper-mobile">
              <div className="success-image-mobile">
                <DemoSuccessImage width="200px" height="200px" />
              </div>
              <div className="success-msg-mobile">
                {"Thanks We've received your request and will be in touch soon."}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DemoPageForm;
