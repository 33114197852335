import { useEffect, useState } from 'react';

const debounce = (func, timeout = 300) => {
  let timer;
  return (/** @type {any} */ ...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' && window.innerWidth,
    height: typeof window !== 'undefined' && window.innerHeight,
  });
  useEffect(() => {
    const debouncedHandleResize = debounce(
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      },
      200,
    );

    // Add event listener
    window.addEventListener('resize', debouncedHandleResize);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
