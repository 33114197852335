import React, { lazy, Suspense } from 'react';
import './style.scss';
import Modal from '../Modal/Modal';
import { getStoredSessionData } from '../../lib/Auth';
import Loader from '../../components/Loader/Loader';
import { useWindowSize } from '../../utils';

const LoginModalEU = lazy(() => import('../../icons/loginModalEU'));
const LoginPageWelcomeIcon = lazy(() => import('../../icons/loginPageWelcome'));
const LoginModalCancel = lazy(() => import('../../icons/loginModalCancel'));
const LoginButton = lazy(() => import('./loginButton'));

const loginModalWelcomeNotes = [
  'Streamline progress tracking with EazyUpdates.',
  'Efficiently monitor individual contributions.',
  'Centralized platform for effective team management.',
];
export function isSignedIn() {
  const userData = getStoredSessionData().userData;
  return (userData !== undefined || userData !== null) && Object.keys(userData).length !== 0;
}

export function isNewOrganisation() {
  const userData = getStoredSessionData().userData;
  return userData.tenantId === null && userData.email !== null;
}

export function LoginModal({ setIsLoginModal }) {
  const windowSize = useWindowSize();
  const isLaptop = windowSize.width < 960;
  return (
    <>
      {!isSignedIn() && (
        <Modal>
          <div className="login-modal">
            <div className="backgroundImg">
              <LoginModalEU />
              <div
                className="login-modal-cancel-icon"
                onClick={() => {
                  setIsLoginModal !== undefined && setIsLoginModal(false);
                }}
              >
                <LoginModalCancel />
              </div>
            </div>
            <div className="login-modal-container">
              <div>
                <div className="login-modal-welcome-icon">
                  {isLaptop ? (
                    <LoginPageWelcomeIcon width="225px" />
                  ) : (
                    <LoginPageWelcomeIcon width="324px" />
                  )}
                </div>
                <div className="login-modal-welcome-to-eu">Welcome to EazyUpdates</div>
                <div className="login-modal-notes">
                  {loginModalWelcomeNotes.map((item, index) => (
                    <div key={index} className="point">
                      <img
                        src="https://storage.googleapis.com/zopping-staging-uploads/2497/images/1024/20240105/imagefile-20240105-054612.webp"
                        alt="icon"
                      />
                      <span className="login-modal-welcome-notes">{item}</span>
                    </div>
                  ))}
                </div>
              </div>
              <Suspense fallback={<Loader />}>
                <LoginButton isNewOrganisation={isNewOrganisation} isSignedIn={isSignedIn} />
              </Suspense>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
export default LoginModal;
