import React from "react";

export default function WorkSpaceLogo({
  color,
  height,
  width,
}) {
  return (
    <svg
      style={{ cursor: "pointer" }}
      width={width !== undefined && width.length > 0 ? width : "188px"}
      height={height !== undefined && height.length > 0 ? height : "60px"}
      viewBox="0 0 188 40"
      version="1.1"
    >
      <title>EazyUpdates</title>
      <g
        id="EazyUpdates"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-86">
          <g id="Group-85">
            <path
              d="M12.819426,-5.17920154e-16 L27.180574,5.17920154e-16 C31.6381677,-3.00926524e-16 33.2545989,0.464128056 34.8842285,1.33566352 C36.5138581,2.20719898 37.792801,3.48614185 38.6643365,5.11577148 C39.5358719,6.74540111 40,8.3618323 40,12.819426 L40,27.180574 C40,31.6381677 39.5358719,33.2545989 38.6643365,34.8842285 C37.792801,36.5138581 36.5138581,37.792801 34.8842285,38.6643365 C33.2545989,39.5358719 31.6381677,40 27.180574,40 L12.819426,40 C8.3618323,40 6.74540111,39.5358719 5.11577148,38.6643365 C3.48614185,37.792801 2.20719898,36.5138581 1.33566352,34.8842285 C0.464128056,33.2545989 2.00617683e-16,31.6381677 -3.45280103e-16,27.180574 L3.45280103e-16,12.819426 C-2.00617683e-16,8.3618323 0.464128056,6.74540111 1.33566352,5.11577148 C2.20719898,3.48614185 3.48614185,2.20719898 5.11577148,1.33566352 C6.74540111,0.464128056 8.3618323,3.00926524e-16 12.819426,-5.17920154e-16 Z"
              id="Rectangle-2"
              fill="#5C8AFF"
            ></path>
            <g
              id="Group-83-Copy"
              transform="translate(11.000000, 10.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M0,0 L5.39689915,0 L5.39689915,4.31460274 L0,4.31460274 L0,0 Z M0,5.76705083 L5.39689915,5.76705083 L5.39689915,10.0816536 L0,10.0816536 L0,5.76705083 Z M0,11.5234163 L5.39689915,11.5234163 L5.39689915,19.6333315 C2.24948054,18.9612669 1.28231525e-15,16.180747 0,12.9623755 L0,11.5234163 L0,11.5234163 Z M18,0.0450550846 L18,11.037953 C18,15.8384757 14.2080474,19.781743 9.41119726,19.9694846 L8.63152088,20 L8.63152088,20 L8.63152088,15.2340026 C10.7170024,15.3393071 12.4929855,13.7340565 12.59829,11.6485749 C12.6014967,11.5850686 12.6031008,11.5214914 12.6031008,11.4579042 L12.6031008,0.0450550846 L12.6031008,0.0450550846 L18,0.0450550846 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
          <g
            id="EazyUpdates"
            transform="translate(50.184000, 10.960000)"
            fill={color}
            fillRule="nonzero"
          >
            <path
              d="M0,0.72 L10.296,0.72 L10.296,2.928 L2.664,2.928 L2.664,7.68 L9.168,7.68 L9.168,9.888 L2.664,9.888 L2.664,14.832 L10.296,14.832 L10.296,17.04 L0,17.04 L0,0.72 Z"
              id="Path"
            ></path>
            <path
              d="M16.896,17.352 C16.208,17.352 15.588,17.252 15.036,17.052 C14.484,16.852 14.016,16.576 13.632,16.224 C13.248,15.872 12.956,15.452 12.756,14.964 C12.556,14.476 12.456,13.952 12.456,13.392 C12.456,12.832 12.556,12.308 12.756,11.82 C12.956,11.332 13.248,10.912 13.632,10.56 C14.016,10.208 14.484,9.932 15.036,9.732 C15.588,9.532 16.208,9.432 16.896,9.432 C18.144,9.432 19.208,9.832 20.088,10.632 L20.088,9.672 C20.088,9.272 20.036,8.9 19.932,8.556 C19.828,8.212 19.668,7.912 19.452,7.656 C19.236,7.4 18.96,7.196 18.624,7.044 C18.288,6.892 17.888,6.816 17.424,6.816 C16.72,6.816 16.144,6.94 15.696,7.188 C15.248,7.436 14.864,7.744 14.544,8.112 L13.152,6.456 C13.552,5.992 14.108,5.568 14.82,5.184 C15.532,4.8 16.424,4.608 17.496,4.608 C18.344,4.608 19.092,4.728 19.74,4.968 C20.388,5.208 20.928,5.544 21.36,5.976 C21.792,6.408 22.12,6.92 22.344,7.512 C22.568,8.104 22.68,8.752 22.68,9.456 L22.68,17.04 L20.208,17.04 L20.208,16.032 C19.328,16.912 18.224,17.352 16.896,17.352 Z M17.424,15.384 C17.968,15.384 18.48,15.272 18.96,15.048 C19.44,14.824 19.816,14.504 20.088,14.088 L20.088,12.72 C19.816,12.304 19.44,11.98 18.96,11.748 C18.48,11.516 17.968,11.4 17.424,11.4 C17.008,11.4 16.652,11.456 16.356,11.568 C16.06,11.68 15.816,11.832 15.624,12.024 C15.432,12.216 15.292,12.432 15.204,12.672 C15.116,12.912 15.072,13.152 15.072,13.392 C15.072,13.632 15.116,13.872 15.204,14.112 C15.292,14.352 15.432,14.568 15.624,14.76 C15.816,14.952 16.06,15.104 16.356,15.216 C16.652,15.328 17.008,15.384 17.424,15.384 Z"
              id="Shape"
            ></path>
            <path
              d="M28.8205132,6.936 L31.08,6.936 L32.4982267,4.92 L34.344,4.92 L34.344,6.672 L28.416,15.024 L34.464,15.024 L34.464,17.04 L25.128,17.04 L25.128,15.288 L31.08,6.936 L25.512,6.936 L25.512,4.92 L30.2490259,4.92 L28.8205132,6.936 Z"
              id="Combined-Shape"
            ></path>
            <path
              d="M40.368,15.648 L35.832,4.92 L38.616,4.92 L40.992,11.088 C41.168,11.584 41.32,12.048 41.448,12.48 C41.576,12.912 41.64,13.224 41.64,13.416 L41.736,13.416 C41.768,13.08 41.832,12.732 41.928,12.372 C42.024,12.012 42.16,11.584 42.336,11.088 L44.544,4.92 L47.352,4.92 L40.776,21.384 L37.968,21.384 L40.368,15.648 Z"
              id="Path"
            ></path>
            <path
              d="M55.92,17.352 C54.896,17.352 53.996,17.192 53.22,16.872 C52.444,16.552 51.792,16.108 51.264,15.54 C50.736,14.972 50.34,14.296 50.076,13.512 C49.812,12.728 49.68,11.864 49.68,10.92 L49.68,0.72 L52.344,0.72 L52.344,10.752 C52.344,12.048 52.624,13.084 53.184,13.86 C53.744,14.636 54.656,15.024 55.92,15.024 C57.168,15.024 58.072,14.636 58.632,13.86 C59.192,13.084 59.472,12.048 59.472,10.752 L59.472,0.72 L62.136,0.72 L62.136,10.92 C62.136,11.864 62.004,12.728 61.74,13.512 C61.476,14.296 61.084,14.972 60.564,15.54 C60.044,16.108 59.396,16.552 58.62,16.872 C57.844,17.192 56.944,17.352 55.92,17.352 Z"
              id="Path"
            ></path>
            <path
              d="M77.328,10.968 C77.328,11.944 77.196,12.824 76.932,13.608 C76.668,14.392 76.304,15.06 75.84,15.612 C75.376,16.164 74.82,16.592 74.172,16.896 C73.524,17.2 72.808,17.352 72.024,17.352 C71.304,17.352 70.64,17.192 70.032,16.872 C69.424,16.552 68.912,16.12 68.496,15.576 L68.496,21.36 L65.904,21.36 L65.904,4.92 L68.4,4.92 L68.4,6.504 C68.816,5.944 69.336,5.488 69.96,5.136 C70.584,4.784 71.272,4.608 72.024,4.608 C72.808,4.608 73.524,4.76 74.172,5.064 C74.82,5.368 75.376,5.796 75.84,6.348 C76.304,6.9 76.668,7.568 76.932,8.352 C77.196,9.136 77.328,10.008 77.328,10.968 Z M71.424,6.816 C70.784,6.816 70.204,6.996 69.684,7.356 C69.164,7.716 68.768,8.192 68.496,8.784 L68.496,13.152 C68.768,13.76 69.164,14.244 69.684,14.604 C70.204,14.964 70.784,15.144 71.424,15.144 C72.4,15.144 73.188,14.792 73.788,14.088 C74.388,13.384 74.688,12.344 74.688,10.968 C74.688,9.608 74.388,8.576 73.788,7.872 C73.188,7.168 72.4,6.816 71.424,6.816 Z"
              id="Shape"
            ></path>
            <path
              d="M88.416,15.528 C88,16.088 87.484,16.532 86.868,16.86 C86.252,17.188 85.576,17.352 84.84,17.352 C84.056,17.352 83.336,17.2 82.68,16.896 C82.024,16.592 81.46,16.164 80.988,15.612 C80.516,15.06 80.148,14.392 79.884,13.608 C79.62,12.824 79.488,11.944 79.488,10.968 C79.488,10.008 79.62,9.136 79.884,8.352 C80.148,7.568 80.516,6.9 80.988,6.348 C81.46,5.796 82.024,5.368 82.68,5.064 C83.336,4.76 84.056,4.608 84.84,4.608 C85.544,4.608 86.196,4.764 86.796,5.076 C87.396,5.388 87.904,5.808 88.32,6.336 L88.32,0 L90.912,0 L90.912,17.04 L88.416,17.04 L88.416,15.528 Z M82.128,10.968 C82.128,12.344 82.428,13.384 83.028,14.088 C83.628,14.792 84.416,15.144 85.392,15.144 C86.016,15.144 86.592,14.964 87.12,14.604 C87.648,14.244 88.048,13.76 88.32,13.152 L88.32,8.808 C88.048,8.2 87.648,7.716 87.12,7.356 C86.592,6.996 86.016,6.816 85.392,6.816 C84.416,6.816 83.628,7.168 83.028,7.872 C82.428,8.576 82.128,9.608 82.128,10.968 Z"
              id="Shape"
            ></path>
            <path
              d="M98.064,17.352 C97.376,17.352 96.756,17.252 96.204,17.052 C95.652,16.852 95.184,16.576 94.8,16.224 C94.416,15.872 94.124,15.452 93.924,14.964 C93.724,14.476 93.624,13.952 93.624,13.392 C93.624,12.832 93.724,12.308 93.924,11.82 C94.124,11.332 94.416,10.912 94.8,10.56 C95.184,10.208 95.652,9.932 96.204,9.732 C96.756,9.532 97.376,9.432 98.064,9.432 C99.312,9.432 100.376,9.832 101.256,10.632 L101.256,9.672 C101.256,9.272 101.204,8.9 101.1,8.556 C100.996,8.212 100.836,7.912 100.62,7.656 C100.404,7.4 100.128,7.196 99.792,7.044 C99.456,6.892 99.056,6.816 98.592,6.816 C97.888,6.816 97.312,6.94 96.864,7.188 C96.416,7.436 96.032,7.744 95.712,8.112 L94.32,6.456 C94.72,5.992 95.276,5.568 95.988,5.184 C96.7,4.8 97.592,4.608 98.664,4.608 C99.512,4.608 100.26,4.728 100.908,4.968 C101.556,5.208 102.096,5.544 102.528,5.976 C102.96,6.408 103.288,6.92 103.512,7.512 C103.736,8.104 103.848,8.752 103.848,9.456 L103.848,17.04 L101.376,17.04 L101.376,16.032 C100.496,16.912 99.392,17.352 98.064,17.352 Z M98.592,15.384 C99.136,15.384 99.648,15.272 100.128,15.048 C100.608,14.824 100.984,14.504 101.256,14.088 L101.256,12.72 C100.984,12.304 100.608,11.98 100.128,11.748 C99.648,11.516 99.136,11.4 98.592,11.4 C98.176,11.4 97.82,11.456 97.524,11.568 C97.228,11.68 96.984,11.832 96.792,12.024 C96.6,12.216 96.46,12.432 96.372,12.672 C96.284,12.912 96.24,13.152 96.24,13.392 C96.24,13.632 96.284,13.872 96.372,14.112 C96.46,14.352 96.6,14.568 96.792,14.76 C96.984,14.952 97.228,15.104 97.524,15.216 C97.82,15.328 98.176,15.384 98.592,15.384 Z"
              id="Shape"
            ></path>
            <path
              d="M112.032,17.16 C110.736,17.16 109.756,16.824 109.092,16.152 C108.428,15.48 108.096,14.416 108.096,12.96 L108.096,6.936 L106.032,6.936 L106.032,4.92 L108.168,4.92 L108.168,1.8 L110.688,1.8 L110.688,4.92 L113.376,4.92 L113.376,6.936 L110.688,6.936 L110.688,12.888 C110.688,13.656 110.856,14.196 111.192,14.508 C111.528,14.82 112.064,14.976 112.8,14.976 L113.376,14.976 L113.376,17.04 C113.184,17.072 112.968,17.1 112.728,17.124 C112.488,17.148 112.256,17.16 112.032,17.16 Z"
              id="Path"
            ></path>
            <path
              d="M121.008,17.352 C120.144,17.352 119.356,17.204 118.644,16.908 C117.932,16.612 117.324,16.188 116.82,15.636 C116.316,15.084 115.924,14.416 115.644,13.632 C115.364,12.848 115.224,11.968 115.224,10.992 C115.224,10 115.372,9.108 115.668,8.316 C115.964,7.524 116.368,6.852 116.88,6.3 C117.392,5.748 117.992,5.328 118.68,5.04 C119.368,4.752 120.112,4.608 120.912,4.608 C121.728,4.608 122.472,4.756 123.144,5.052 C123.816,5.348 124.388,5.764 124.86,6.3 C125.332,6.836 125.7,7.472 125.964,8.208 C126.228,8.944 126.36,9.744 126.36,10.608 C126.36,10.784 126.352,10.968 126.336,11.16 C126.32,11.352 126.296,11.536 126.264,11.712 L117.816,11.712 C117.928,12.768 118.268,13.608 118.836,14.232 C119.404,14.856 120.192,15.168 121.2,15.168 C121.92,15.168 122.544,15.02 123.072,14.724 C123.6,14.428 124.04,14 124.392,13.44 L126.048,14.808 C125.456,15.624 124.752,16.252 123.936,16.692 C123.12,17.132 122.144,17.352 121.008,17.352 Z M120.912,6.72 C120.096,6.72 119.424,6.98 118.896,7.5 C118.368,8.02 118.024,8.784 117.864,9.792 L123.768,9.792 C123.688,8.784 123.392,8.02 122.88,7.5 C122.368,6.98 121.712,6.72 120.912,6.72 Z"
              id="Shape"
            ></path>
            <path
              d="M135.24,7.992 C134.568,7.128 133.784,6.696 132.888,6.696 C132.424,6.696 132.052,6.832 131.772,7.104 C131.492,7.376 131.352,7.696 131.352,8.064 C131.352,8.8 131.8,9.272 132.696,9.48 L133.632,9.696 C134.864,9.968 135.796,10.392 136.428,10.968 C137.06,11.544 137.376,12.384 137.376,13.488 C137.376,14 137.268,14.492 137.052,14.964 C136.836,15.436 136.524,15.848 136.116,16.2 C135.708,16.552 135.212,16.832 134.628,17.04 C134.044,17.248 133.384,17.352 132.648,17.352 C131.592,17.352 130.668,17.112 129.876,16.632 C129.084,16.152 128.44,15.552 127.944,14.832 L129.552,13.344 C129.936,13.936 130.392,14.404 130.92,14.748 C131.448,15.092 132.056,15.264 132.744,15.264 C133.352,15.264 133.848,15.12 134.232,14.832 C134.616,14.544 134.808,14.152 134.808,13.656 C134.808,13.192 134.644,12.836 134.316,12.588 C133.988,12.34 133.504,12.144 132.864,12 L131.904,11.784 C130.896,11.56 130.124,11.156 129.588,10.572 C129.052,9.988 128.784,9.216 128.784,8.256 C128.784,7.776 128.876,7.316 129.06,6.876 C129.244,6.436 129.512,6.048 129.864,5.712 C130.216,5.376 130.648,5.108 131.16,4.908 C131.672,4.708 132.264,4.608 132.936,4.608 C133.832,4.608 134.604,4.792 135.252,5.16 C135.9,5.528 136.432,6.008 136.848,6.6 L135.24,7.992 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
