import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import {
  BLOG_LISTING,
  BLOG_PAGE,
  LANDING_PAGE,
  PRIVACY_POLICY,
  TERMS_AND_CONDITION,
  PAGE_NOT_FOUND,
} from './constants';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { msalConfig } from './config.ts';
import LandingPageHeader from './components/LandingPageHeader';

const LandingPage = lazy(() => import('./pages/LandingPage'));
const PrivacyPolicyPage = lazy(() => import('./components/PrivacyPolicy'));
const TermsAndConditionPage = lazy(() => import('./components/TermsAndConditions'));
const BlogListing = lazy(() => import('./components/BlogListing'));
const BlogPage = lazy(() => import('./pages/BlogPage'));
const PageNotFound = lazy(() => import('./pages/Home/404'));

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <HelmetProvider>
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
            theme="light"
          />
          <LandingPageHeader />
          <Helmet>
            <title>EazyUpdates | Monitor your progress</title>
            <meta
              name="description"
              content="Use our One stop portal to track status of Projects, Teams, and Individual within Zopsmart Organization, creating Employee's Workflow efficient and easy"
            />
          </Helmet>
          <Suspense fallback={<></>}>
            <Routes>
              <Route path={PAGE_NOT_FOUND} element={<PageNotFound />} />
              <Route path={LANDING_PAGE} element={<LandingPage />} />
              <Route
                path={`${LANDING_PAGE}${TERMS_AND_CONDITION}`}
                element={<TermsAndConditionPage />}
              />
              <Route path={`${LANDING_PAGE}${PRIVACY_POLICY}`} element={<PrivacyPolicyPage />} />
              <Route path={`${LANDING_PAGE}${BLOG_LISTING}`} element={<BlogListing />} />
              <Route path={`${LANDING_PAGE}${BLOG_PAGE}`} element={<BlogPage />} />
            </Routes>
          </Suspense>
        </HelmetProvider>
      </BrowserRouter>
    </MsalProvider>
  );
}

export default App;
