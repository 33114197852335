import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../constants';

const getHeaders = () => {
  return { Accept: 'application/json', 'Content-Type': 'application/json' };
};
const postEmployeeIdAndAuthToken = async ({
  name,
  email,
  profileUrl,
  token,
  timezone,
  domain,
  fcmToken,
  authProvider,
}) => {
  const params = { name, email, profileUrl, token, timezone, domain, fcmToken, authProvider };
  const response = await axios.post(`${BASE_URL}/login`, params, getHeaders());

  return response?.data;
};

const PostImage = async (image) => {
  const formData = new FormData();
  formData.append('imageUpload', image);
  const fileType = image.type.split('/')[1];
  if (image.size > 1 * 1024 * 1024) {
    toast.error('File size exceeds the maximum limit of 1MB.');
    return;
  }
  if (!['png', 'jpg', 'jpeg'].includes(fileType)) {
    toast.error('Only PNG, JPG, and JPEG files are allowed.');
    return;
  }
  const res = await axios.post(
    `${BASE_URL}/projects/image?fileExtension=${fileType}`,
    formData,
    getHeaders(),
  );
  if (res?.data !== undefined && res.data?.data !== undefined) {
    return res.data.data;
  }
};

const postDemoRequest = async ({ name, email, phoneNumber, flag, message, website }) => {
  const res = await axios.post(
    `${BASE_URL}/landingPage/contact-details`,
    { name, email, phoneNumber, flag, message, website },
    getHeaders(),
  );

  return res;
};

const getPlans = async () => {
  const transaction = await axios.get(`${BASE_URL}/plan`, getHeaders());

  return transaction.data;
};

export { postEmployeeIdAndAuthToken, postDemoRequest, getPlans, PostImage };
