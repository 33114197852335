import React from "react";

export default function LoginModalCancel() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Close</title>
      <g
        id="Landing-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Eazyupdates-Website---Login-Popup"
          transform="translate(-1276, -228)"
        >
          <g
            id="CaretDown-Copy-2"
            transform="translate(1288, 240) rotate(180) translate(-1288, -240)translate(1276, 228)"
          >
            <g
              id="CaretDown"
              transform="translate(12, 12) rotate(180) translate(-12, -12)translate(-0, 0)"
            >
              <g id="Group-5" transform="translate(-0, 0)">
                <path
                  d="M24,0 L24,24 L-2.7706961e-16,24 L-2.7706961e-16,0 L24,0 Z"
                  id="Vector"
                ></path>
                <g
                  id="Group-3"
                  transform="translate(11.6542, 11.7731) rotate(-360) translate(-11.6542, -11.7731)translate(6, 6)"
                  fillRule="nonzero"
                  stroke="#202020"
                  strokeDasharray="0,0"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.70999998"
                >
                  <line
                    x1="0"
                    y1="0"
                    x2="11.3084613"
                    y2="11.5461992"
                    id="Path-2"
                  ></line>
                  <line
                    x1="0"
                    y1="0"
                    x2="11.3084613"
                    y2="11.5461992"
                    id="Path-2-Copy"
                    transform="translate(5.6542, 5.7731) scale(-1, 1) translate(-5.6542, -5.7731)"
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
