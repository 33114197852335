function getStoredSessionData() {
  return {
    token: localStorage.getItem('token') ?? '',
    userData: JSON.parse(localStorage.getItem('userData') ?? '{}'),
    accessTokenForNewOrg: localStorage.getItem('createOrg') ?? '',
  };
}

function setStoredSessionData(sessionData) {
  localStorage.setItem('token', sessionData.token);
  localStorage.setItem('userData', JSON.stringify(sessionData.userData));
  window.dispatchEvent(new Event('userData'));
  localStorage.setItem('accessTokenForNewOrg', sessionData.accessTokenForNewOrg ?? '');
}

export { getStoredSessionData, setStoredSessionData };
