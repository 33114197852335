import './styles.scss';
import React, { useEffect, useRef } from 'react';

function Modal({ children }) {
  const modalRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [modalRef]);

  return (
    <div ref={modalRef} className="ModalPopUp">
      <div className="modal">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
}

export default Modal;
