import React from "react";

export default function MenuIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="landing-page---mobile-view"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="easyupdate-website-mobile" transform="translate(-16, -18)">
          <g id="Home" transform="translate(16, 18)">
            <polygon
              id="Rectangle"
              points="0 0 12 0 24 0 24 24 0 24 0 6.94736842"
            ></polygon>
            <g
              id="Group-55"
              transform="translate(3.4737, 5.0526)"
              fill="#0A0A0A"
              stroke="#FFFFFF"
              strokeWidth="1.4"
            >
              <line
                x1="0"
                y1="0.631578947"
                x2="17.6842105"
                y2="0.631578947"
                id="Path-26"
              ></line>
              <line
                x1="0"
                y1="6.94736842"
                x2="17.6842105"
                y2="6.94736842"
                id="Path-26-Copy"
              ></line>
              <line
                x1="0"
                y1="13.2631579"
                x2="17.6842105"
                y2="13.2631579"
                id="Path-26-Copy-2"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
