import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './style.scss';

export default function Phone({
  placeholder,
  onChange,
  country,
  required = false,
  label,
  value,
  ...props
}) {
  const [defaultCountryCode] = useState('IN');
  const [defaultPlaceHolder] = useState('Phone');

  return (
    <PhoneInput
      {...props}
      placeholder={placeholder ?? defaultPlaceHolder}
      onChange={onChange}
      defaultCountry={country ?? defaultCountryCode}
      displayInitialValueAsLocalNumber
      rules={{ required }}
    />
  );
}
