import './styles.scss';
import React from 'react';

function Loader() {
  return (
    <div className="Loader" data-testid="loader">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loader;
