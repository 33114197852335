export const LOGOUT = '/logout';
export const DASHBOARD = '/user/dashboard';
export const JOIN_CREATE_ORGANISATION = '/user/organization';
export const LANDING_PAGE = '/';
export const TERMS_AND_CONDITION = '/terms-condition';
export const PRIVACY_POLICY = '/privacy-policy';
export const BLOG_LISTING = '/blog';
export const BLOG_PAGE = '/blog/:slug';
export const PAGE_NOT_FOUND = '*';

export const GOOGLE_LOGIN_CLIENT_ID =
  window?.env?.REACT_APP_GOOGLE_LOGIN_CLIENT_ID !== undefined &&
  window?.env?.REACT_APP_GOOGLE_LOGIN_CLIENT_ID !== null
    ? window?.env?.REACT_APP_GOOGLE_LOGIN_CLIENT_ID
    : process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;

export const BASE_URL =
  window?.env?.REACT_APP_BASE_URL !== undefined && window?.env?.REACT_APP_BASE_URL !== null
    ? window?.env?.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL;

export const FARO_NAME =
  window?.env?.REACT_APP_FARO_APP_NAME !== undefined &&
  window?.env?.REACT_APP_FARO_APP_NAME !== null
    ? window?.env?.REACT_APP_FARO_APP_NAME
    : process.env.REACT_APP_FARO_APP_NAME;

export const FARO_URL =
  window?.env?.REACT_APP_FARO_URL !== undefined && window?.env?.REACT_APP_FARO_URL !== null
    ? window?.env?.REACT_APP_FARO_URL
    : process.env.REACT_APP_FARO_URL;

export const SECRET_KEY =
  window?.env?.REACT_APP_CAPTCHA_KEY !== undefined && window?.env?.REACT_APP_CAPTCHA_KEY !== null
    ? window?.env?.REACT_APP_CAPTCHA_KEY
    : process.env.REACT_APP_CAPTCHA_KEY;

export const MICROSOFT_LOGIN_CLIENT_ID =
  window?.env?.REACT_APP_MICROSOFT_LOGIN_CLIENT_ID !== undefined &&
  window?.env?.REACT_APP_MICROSOFT_LOGIN_CLIENT_ID !== null
    ? window?.env?.REACT_APP_MICROSOFT_LOGIN_CLIENT_ID
    : process.env.REACT_APP_MICROSOFT_LOGIN_CLIENT_ID;
