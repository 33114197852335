import ReactGA from "react-ga4";

const GaEvents = (props) => {
  ReactGA.event({
    category: props.category,
    action: props.action,
    label: props.label,
  });
};

export default GaEvents;
